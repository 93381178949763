var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computeAccuracyStats } from "../scoring/models/Score";
import { GameStatus } from "./GameState";
const TIME_OFFSET_SETTING = "timingOffset";
export default class GameController {
    constructor(state, audioManager, timer, graphicsManager, inputManger, scoreManager, settingsManager) {
        this.state = state;
        this.audioManager = audioManager;
        this.timer = timer;
        this.graphicsManager = graphicsManager;
        this.inputManger = inputManger;
        this.scoreManager = scoreManager;
        this.settingsManager = settingsManager;
        this.inputState = inputManger.getInputState();
        this.settingsManager.addObserver(TIME_OFFSET_SETTING, (newOffset) => {
            this.state.timingOffset = newOffset;
        });
        this.audioManager.addEventListener("songEnd", () => __awaiter(this, void 0, void 0, function* () {
            yield this.endMap();
        }));
    }
    loadBeatmap(beatmap) {
        return __awaiter(this, void 0, void 0, function* () {
            this.state.setStatus(GameStatus.LOADING);
            this.state.loadBeatmap(beatmap);
            const highScore = yield this.scoreManager.getHighscore(beatmap, this.state.getGameMode());
            this.state.score.highScore = highScore.highScore;
            this.state.score.maxCombo = highScore.maxCombo;
            return Promise.all([
                this.audioManager.loadBeatmap(beatmap),
                this.graphicsManager.loadBeatmap(beatmap),
            ]);
        });
    }
    pause() {
        this.audioManager.onGamePause();
        this.timer.pause();
        this.state.setStatus(GameStatus.PAUSED);
        computeAccuracyStats(this.state.score);
    }
    start() {
        return __awaiter(this, void 0, void 0, function* () {
            this.timer.reset();
            const startTime = yield this.audioManager.onGameStart();
            this.timer.start(startTime);
            this.graphicsManager.onGameStart();
            this.state.setStatus(GameStatus.PLAYING);
            //this.state.setStatus(GameStatus.STARTING);
        });
    }
    resume() {
        return __awaiter(this, void 0, void 0, function* () {
            const resumeTime = yield this.audioManager.onGameResume();
            this.timer.resume(resumeTime);
            this.state.setStatus(GameStatus.PLAYING);
            //this.state.setStatus(GameStatus.RESUMING);
        });
    }
    restart() {
        return __awaiter(this, void 0, void 0, function* () {
            this.state.reset();
            this.state.loadBeatmap(this.state.beatmap);
            this.graphicsManager.onGameRestart();
            this.timer.reset();
            const startTime = yield this.audioManager.onGameRestart();
            this.timer.start(startTime);
            this.state.setStatus(GameStatus.PLAYING);
            //this.state.setStatus(GameStatus.STARTING);
        });
    }
    endMap() {
        return __awaiter(this, void 0, void 0, function* () {
            computeAccuracyStats(this.state.score);
            this.state.score.beatmap = this.state.beatmap;
            yield this.scoreManager.processScore(this.state.score);
            this.state.setStatus(GameStatus.GAME_OVER);
        });
    }
    returnToMainMenu() {
        return __awaiter(this, void 0, void 0, function* () {
            this.state.reset();
            this.graphicsManager.onReturnToMenu();
            this.state.setStatus(GameStatus.MENU);
        });
    }
    update(deltaTime) {
        // update input state
        this.inputManger.update();
        // update game state
        this.state.update(this.timer.getCurrentTime(), this.inputState);
        this.state.updateScore();
        // play audio based on current state
        this.audioManager.update(this.state);
        // render current state
        this.graphicsManager.update(this.state, deltaTime);
    }
}
