var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Raycaster, Vector2 } from "three";
import convertBeatmap from "../beatmap/converters/BeatmapConverter";
import { deserializeBeatmap } from "../beatmap/serialization/BeatmapDeserializer";
import { GAMEMODE_CLASSIC, GAMEMODE_TAIKO, GAMEMODE_TONO, } from "../game/GameModes";
import { GameStatus } from "../game/GameState";
import { getColor } from "../graphics/JudgementColors";
import { computeAccuracyStats } from "../scoring/models/Score";
const TOUCH_COLLISION_START_EVENT = new CustomEvent("collision-enter", {
    detail: {
        id: -1,
        collisionShapes: [[null, { center: { x: 0, y: 0, z: 0 } }]],
    },
});
const TOUCH_COLLISION_END_EVENT = new CustomEvent("collision-exit", {
    detail: { id: -1 },
});
AFRAME.registerSystem("scene-controller", {
    init: function () {
        this.menu = document.querySelector("#menu");
        this.menu.addEventListener("song-change", () => {
            this.schedulePreload();
        });
        this.inputDevices = [
            [document.querySelector("#keyboard")],
            [document.querySelector("#drum")],
            [
                document.querySelector("#tone-display"),
                document.querySelector("#microphone"),
                document.querySelector("#trombone"),
            ],
        ];
        this.mallets = [
            document.querySelector("#mallet1"),
            document.querySelector("#mallet2"),
        ];
        this.el.addEventListener("loaded", () => {
            this.microphone =
                document.querySelector("#microphone").components["microphone"];
            this.keyboard =
                document.querySelector("#keyboard").components["keyboard"];
            this.drum = document.querySelector("#drum").components["drum"];
            this.adjustFov();
            window.addEventListener("orientationchange", () => {
                this.adjustFov();
            });
            window.addEventListener("resize", () => {
                this.adjustFov();
            });
            this.game = document.querySelector("#game").components["game"];
            this.gameMode = 0;
            const noteEmitters = this.keyboard.noteEmitters;
            this.game.addGameStateListener("score", () => {
                if (this.gameMode === 1) {
                    const colorArr = [];
                    for (const event of this.game.controller.state.events) {
                        if (event.judgement !== 2 && event.note.timeDelta <= 180) {
                            //TODO clean up
                            colorArr.push(getColor(event.judgement));
                        }
                    }
                    this.drum.setPrevRippleColors(colorArr);
                }
                else {
                    for (const event of this.game.controller.state.events) {
                        for (let i = 0; i < event.note.width; i++) {
                            noteEmitters[event.note.key + i].components["note-emitter"].activate(event.judgement);
                        }
                    }
                }
                this.updateInfoText();
            });
            this.game.addGameStateListener("status", (status) => {
                if (status === GameStatus.PLAYING) {
                    this.el.systems["detachable"].isEnabled = false;
                    this.disablePointers();
                    this.infoText.setAttribute("width", 1);
                    this.setMalletsLocked(true);
                }
                else {
                    this.el.systems["detachable"].isEnabled = true;
                    this.enablePointers();
                    this.setMalletsLocked(false);
                    this.infoText.setAttribute("width", 2);
                }
                this.updateInfoText();
                this.updatePauseGameoverPanel();
                if (status === GameStatus.GAME_OVER) {
                    const score = this.game.getScore();
                    const beatmapHash = score.beatmap.hash;
                    const gameMode = this.gameMode;
                    if (score.score === score.highScore) {
                        setTimeout(() => {
                            document
                                .querySelector("#high-scores-text")
                                .components["leaderboard"].updateLeaderboard(beatmapHash, gameMode);
                        }, 2000);
                    }
                    this.updateHighscoreText();
                    gtag("event", "songend", {
                        mode: gameMode,
                        map: beatmapHash,
                    });
                }
            });
            this.loadSongTimeout = null;
            this.menu.addEventListener("difficulty-change", () => {
                this.schedulePreload();
            });
            this.menu.addEventListener("game-mode-change", (e) => {
                this.gameMode = e.detail;
                this.microphone.disableMic();
                for (const inputDeviceList of this.inputDevices) {
                    for (const inputDevice of inputDeviceList) {
                        inputDevice.setAttribute("visible", false);
                        this.el.systems["collision-detection"].disableColliderGroup(inputDevice.id);
                    }
                }
                for (const inputDevice of this.inputDevices[this.gameMode]) {
                    inputDevice.setAttribute("visible", true);
                    this.el.systems["collision-detection"].enableColliderGroup(inputDevice.id);
                }
                switch (this.gameMode) {
                    case GAMEMODE_CLASSIC:
                        this.game.setClassicGameMode();
                        break;
                    case GAMEMODE_TAIKO:
                        this.game.setTaikoGameMode();
                        break;
                    case GAMEMODE_TONO:
                        this.game.setTonoGameMode();
                        this.microphone.enableMic();
                        break;
                }
                this.schedulePreload();
            });
        });
        document.addEventListener("keydown", (e) => {
            if (document.activeElement != document.getElementById("url-input")) {
                if (e.key === "p") {
                    this.pauseResume();
                }
            }
        });
        // Vive and WMR pause
        document.addEventListener("trackpaddown", () => {
            this.pauseResume();
        });
        // Oculus pause
        document.addEventListener("abuttondown", () => {
            this.pauseResume();
        });
        document.addEventListener("xbuttondown", () => {
            this.pauseResume();
        });
        // Hand tracking pause
        const leftHand = document.querySelector("#leftHand");
        const rightHand = document.querySelector("#rightHand");
        leftHand.addEventListener("triggerup", () => {
            if (leftHand.components["generic-tracked-controller-controls"]
                .controllerEventsActive) {
                this.pauseResume(false);
            }
        });
        rightHand.addEventListener("triggerup", () => {
            if (rightHand.components["generic-tracked-controller-controls"]
                .controllerEventsActive) {
                this.pauseResume(false);
            }
        });
        // Retry Button
        document.querySelector("#retry-button").addEventListener("click", () => {
            this.game.restart();
        });
        // Resume Button
        document.querySelector("#resume-button").addEventListener("click", () => {
            this.pauseResume();
        });
        // Return to Menu Button
        document
            .querySelector("#back-to-menu-button")
            .addEventListener("click", () => {
            this.returnToMenu();
        });
        this.infoText = document.querySelector("#info-text");
        // Initialize loading text
        this.loadingText = document.querySelector("#loading-text");
        // Hide keyboard specific instructions for VR
        this.el.addEventListener("exit-vr", () => {
            this.updateInfoText();
        });
        this.el.addEventListener("enter-vr", () => {
            if (this.gameMode !== 0) {
                this.el.systems["collision-detection"].disableColliderGroup("keyboard");
            }
            if (this.gameMode !== 1) {
                this.el.systems["collision-detection"].disableColliderGroup("drum");
            }
            if (this.gameMode !== 2) {
                this.el.systems["collision-detection"].disableColliderGroup("trombone");
            }
            this.el.systems.audio.audioManager.startContext();
            this.updateInfoText();
            this.el.xrSession.addEventListener("visibilitychange", (eventData) => __awaiter(this, void 0, void 0, function* () {
                switch (eventData.session.visibilityState) {
                    case "visible":
                        //...
                        break;
                    case "visible-blurred":
                        //...
                        break;
                    case "hidden":
                        this.pauseResume(false);
                        this.menu.components["menu"].audio.pause();
                        yield this.el.xrSession.end();
                        break;
                }
            }));
        });
        this.menu.addEventListener("downloadStart", () => {
            this.loadingText.setAttribute("value", "Loading...");
        });
        this.menu.addEventListener("progress", (event) => {
            const { loaded, total } = event;
            let downloadUnits;
            let downloadAmount;
            if (total == 0) {
                downloadAmount = Math.round(loaded / 1000);
                downloadUnits = "kB";
            }
            else {
                downloadAmount = Math.round((loaded / total) * 100);
                downloadUnits = "%";
            }
            this.loadingText.setAttribute("value", "Downloaded " + downloadAmount + downloadUnits);
            if (loaded == total) {
                this.loadingText.setAttribute("value", "");
            }
        });
        this.menu.addEventListener("downloadComplete", () => {
            this.loadingText.setAttribute("value", "");
        });
        this.el.systems["collision-detection"].disableColliderGroup("pauseMenu");
        // Touch input
        const raycaster = new Raycaster();
        document.addEventListener("touchstart", (e) => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            let keyTouched = false;
            for (const touch of e.changedTouches) {
                const touchCoordinates = new Vector2();
                touchCoordinates.x = (touch.clientX / width) * 2 - 1;
                touchCoordinates.y = -(touch.clientY / height) * 2 + 1;
                raycaster.setFromCamera(touchCoordinates, this.el.camera);
                const keys = this.keyboard.keys;
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    const intersections = raycaster.intersectObject(key.getObject3D("mesh"));
                    if (intersections.length > 0) {
                        TOUCH_COLLISION_START_EVENT.detail.collisionShapes[0][1].center.x =
                            intersections[0].point.x;
                        TOUCH_COLLISION_START_EVENT.detail.collisionShapes[0][1].center.z =
                            intersections[0].point.z;
                        key.dispatchEvent(TOUCH_COLLISION_START_EVENT);
                        setTimeout(() => key.dispatchEvent(TOUCH_COLLISION_END_EVENT), 10);
                        keyTouched = true;
                        break;
                    }
                }
            }
            if (!keyTouched) {
                this.pauseResume();
            }
        });
        const playButton = document.getElementById("play-button");
        this.beatmapSet = {
            info: {},
            beatmaps: null,
        };
        this.beatmap = {
            id: null,
            info: {
                name: null,
                creator: null,
                type: null,
            },
            notes: [],
            set: this.beatmapSet,
        };
        playButton.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
            const menu = this.menu.components["menu"];
            menu.audio.pause();
            this.menu.object3D.visible = false;
            this.el.systems["collision-detection"].disableColliderGroup("menu");
            this.menu.object3D.position.y = -100;
            const selectedMap = menu.getSelectedMap();
            if (this.loadSongTimeout) {
                clearTimeout(this.loadSongTimeout);
                this.loadSongTimeout = null;
            }
            yield this.preloadBeatmap(selectedMap);
            let temp = null;
            if (typeof selectedMap.set.info.audioSrc === "number") {
                const audio = yield this.el.systems["db"].beatmapSetRepository.getSong(selectedMap.set.info.audioSrc);
                temp = selectedMap.set.info.audioSrc;
                selectedMap.set.info.audioSrc = URL.createObjectURL(audio.data);
            }
            if (selectedMap.set.info.audioSrc.startsWith("https://")) {
                //cross origin
                temp = selectedMap.set.info.audioSrc;
                selectedMap.set.info.audioSrc = yield fetch(selectedMap.set.info.audioSrc)
                    .then((response) => response.blob())
                    .then((blob) => URL.createObjectURL(blob));
            }
            if (temp) {
                yield this.loadBeatmap(selectedMap);
                selectedMap.set.info.audioSrc = temp;
            }
            else {
                this.loadBeatmap(selectedMap);
            }
            gtag("event", "play", {
                mode: this.gameMode,
                creator: selectedMap.info.creator
                    ? selectedMap.info.creator
                    : selectedMap.set.info.creator,
                name: selectedMap.set.info.song + "_" + selectedMap.info.name,
                map: selectedMap.hash,
            });
        }));
    },
    updateHighscoreText: function () {
        return __awaiter(this, void 0, void 0, function* () {
            const selectedMap = this.menu.components["menu"].getSelectedMap();
            const localHighscore = yield this.el.systems.scores.scoreManager.scoreRepository.getHighscore(selectedMap, this.gameMode);
            const accuracyRankStr = localHighscore.highScoreAccuracy
                ? ` (${localHighscore.highScoreAccuracy.toFixed(2)}% - ${localHighscore.highScoreRank})`
                : "";
            document
                .querySelector("#highscore-text")
                .setAttribute("value", `Highscore: ${localHighscore.highScore + accuracyRankStr}`);
        });
    },
    schedulePreload: function (ms = 2000) {
        if (this.loadSongTimeout) {
            clearTimeout(this.loadSongTimeout);
        }
        document.querySelector("#high-scores-text").setAttribute("value", "");
        this.updateHighscoreText();
        this.loadSongTimeout = setTimeout(() => __awaiter(this, void 0, void 0, function* () {
            const selectedMap = this.menu.components["menu"].getSelectedMap();
            yield this.preloadBeatmap(selectedMap);
            yield document
                .querySelector("#high-scores-text")
                .components["leaderboard"].updateLeaderboard(selectedMap.hash, this.gameMode);
            yield this.el.systems.scores.scoreManager.scoreRepository.syncHighscore(selectedMap, this.gameMode);
            this.updateHighscoreText();
            this.loadSongTimeout = null;
        }), ms);
    },
    preloadBeatmap: function (beatmap) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!isNaN(beatmap.info.src)) {
                const map = yield this.el.systems["db"].beatmapSetRepository.getBeatmap(beatmap.info.src);
                beatmap.info.src = URL.createObjectURL(map.data);
            }
            const beatmapRaw = yield fetch(beatmap.info.src).then((response) => {
                return response.blob();
            });
            yield deserializeBeatmap(beatmapRaw, beatmap);
            yield convertBeatmap(beatmap, this.gameMode);
        });
    },
    enablePointers: function () {
        if (this.el.sceneEl.is("vr-mode")) {
            const leftHand = document.querySelector("#leftHand");
            const rightHand = document.querySelector("#rightHand");
            leftHand.setAttribute("raycaster", "enabled", true);
            leftHand.setAttribute("raycaster", "showLine", true);
            rightHand.setAttribute("raycaster", "enabled", true);
            rightHand.setAttribute("raycaster", "showLine", true);
        }
    },
    disablePointers: function () {
        const leftHand = document.querySelector("#leftHand");
        const rightHand = document.querySelector("#rightHand");
        leftHand.setAttribute("raycaster", "enabled", false);
        leftHand.setAttribute("raycaster", "showLine", false);
        rightHand.setAttribute("raycaster", "enabled", false);
        rightHand.setAttribute("raycaster", "showLine", false);
    },
    setMalletsLocked(locked) {
        for (const mallet of this.mallets) {
            mallet.components["detachable"].locked = locked;
        }
    },
    returnToMenu: function () {
        this.game.returnToMenu();
        this.menu.components.menu.el.object3D.visible = true;
        this.menu.components.menu.audio.play();
        this.el.systems["collision-detection"].enableColliderGroup("menu");
        this.menu.object3D.position.y = 1.5;
    },
    loadBeatmap: function (beatmap) {
        return __awaiter(this, void 0, void 0, function* () {
            //duration of each beat in milliseconds
            // this.beatDuration = 60000 / beatmap.sections[0].bpm;
            // this.beatDurationMultiplier = (2 * Math.PI) / this.beatDuration;
            yield this.game.loadBeatmap(beatmap);
            this.game.startGame();
        });
    },
    adjustFov: function () {
        const camera = this.el.camera;
        if (camera.fov != 80) {
            camera.fov = 80;
            camera.position.z = 0;
            camera.updateProjectionMatrix();
        }
        const currentHFOV = (2 *
            Math.atan(Math.tan((camera.fov * Math.PI) / 180 / 2) * camera.aspect) *
            180) /
            Math.PI;
        if (currentHFOV < 80) {
            camera.position.z = 1 - currentHFOV / 80;
        }
    },
    pauseResume: function (resumeEnabled = true) {
        if (this.game.controller.state.status === GameStatus.PAUSED &&
            resumeEnabled) {
            this.game.resume();
        }
        else if (this.game.controller.state.status === GameStatus.PLAYING) {
            this.game.pauseGame();
        }
    },
    updatePauseGameoverPanel: function () {
        const pauseGameoverPanel = document.getElementById("pause-gameover-panel");
        const pauseGameOverText = document.getElementById("pause-gameover-text");
        const scoreText = document.getElementById("score-text");
        const accuracyText = document.getElementById("accuracy-text");
        const rankText = document.getElementById("rank-text");
        const statsText = document.getElementById("stats-text");
        const resumeButton = document.getElementById("resume-button");
        if (this.game.getStatus() === GameStatus.PAUSED ||
            this.game.getStatus() === GameStatus.GAME_OVER) {
            this.el.systems["collision-detection"].enableColliderGroup("pauseMenu");
            if (this.game.getStatus() === GameStatus.GAME_OVER) {
                pauseGameOverText.setAttribute("value", "Game Over");
                resumeButton.setAttribute("visible", "false");
            }
            else {
                pauseGameOverText.setAttribute("value", "Paused");
                resumeButton.setAttribute("visible", "true");
            }
            const score = this.game.getScore();
            computeAccuracyStats(score);
            const pbString = score.score === score.highScore ? " (Personal Best!)" : "";
            scoreText.setAttribute("value", "Score: " + score.score + pbString);
            accuracyText.setAttribute("value", "Accuracy: " + score.accuracy.toFixed(2) + "%");
            rankText.setAttribute("value", "Rank: " + score.rank);
            statsText.setAttribute("value", Object.entries(score.judgementCounts)
                .map((judgementCount) => {
                return judgementCount[0] + " " + judgementCount[1];
            })
                .join("  "));
            pauseGameoverPanel.object3D.visible = true;
        }
        else {
            this.el.systems["collision-detection"].disableColliderGroup("pauseMenu");
            pauseGameoverPanel.object3D.visible = false;
        }
    },
    updateInfoText: function () {
        if (this.game.getStatus() === GameStatus.PLAYING) {
            this.infoText.setAttribute("value", "Combo: " +
                this.game.getScore().combo +
                " (" +
                this.game.getScore().maxCombo +
                ")\nScore: " +
                this.game.getScore().score +
                " (" +
                this.game.getScore().highScore +
                ")");
        }
        else if (this.game.getStatus() === GameStatus.LOADING) {
            this.infoText.setAttribute("value", "Loading...");
        }
        else {
            this.infoText.setAttribute("value", "");
        }
    },
});
